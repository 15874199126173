.Container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
  max-width: 100%;
  @media (min-width: 576px) {
    max-width: 540px;
  }
  @media (min-width: 768px) {
    max-width: 720px;
  }
  @media (min-width: 992px) {
    max-width: 960px;
  }
  @media (min-width: 1200px) {
    max-width: 1140px;
  }
  @media (min-width: 1440px) {
    max-width: 90% !important;
  }
  // @media (min-width: 1900px) {
  //   max-width: 1630px !important;
  // }

  // @media (min-width: 2100px) {
  //   max-width: 1830px !important;
  // }

  // @media (min-width: 2200px) {
  //   max-width: 1930px !important;
  // }

  // @media (min-width: 2500px) {
  //   max-width: 2230px !important;
  // }
}
